<template>
  <div class="container" id="container">
    <div class="filter">
      <ul>
        <a href="javascript:;" v-for="(item,index) in filter" :key="index" :class="checkedFilter.code == item.code ? 'active' : ''" @click="checkType(item)">{{item.name}}<i></i></a>
      </ul>
    </div>
    <div :class="edit ? 'list edit' : 'list'">
      <div class="nodata" v-show="list.length < 1"><img src="../assets/img/nodata.png" alt="暂无数据" /></div>
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="checkbox" :class="isChecked(item) ? 'checked' : ''" @click="check(item)"><i></i></div>
        <div class="box">
          <div class="user">
            <div class="info">
              <img :src="item.headPortrait" :alt="item.nickname" />{{item.nickname}}
            </div>
            <a href="javascript:;" :class="item.isPraise > 0 ? 'like' : ''"><i></i>({{item.isPraise}})</a>
          </div>
          <div class="time">{{item.criticismDate.substr(0,10)}}</div>
          <div class="text">{{item.content}}</div>
          <div class="for">
            <img :src="item.baseCover" :alt="item.baseTitle" />
            <div>
              <h1>{{item.baseTitle}}</h1>
              <p>{{item.baseContent}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="manage">
      <a class="toedit" :class="edit ? 'editnow' : ''" href="javascript:;" @click="showEdit">管理</a>
      <div class="btns">
        <a href="javascript:;" @click="checkall" v-if="checkedList.length < list.length">全选</a>
        <a href="javascript:;" @click="clearall" v-else>反选</a>
        <a href="javascript:;" @click="del">删除</a>
        <a href="javascript:;" @click="edit = false">取消</a>
      </div>
    </div>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  export default {
    name: 'Comment',
    data () {
      return{
        // 评论类型筛选列表
        filter: [
          {name: '全部', code: ''},
          {name: '商品', code: 4},
          {name: '商家', code: 3}
        ],
        checkedFilter: {}, // 选择的评论类型
        edit: false, // 编辑状态
        loading: false, // 正在获取数据
        pageCount: 1, // 最大页数
        pageIndex: 1, // 当前页码
        pageSize: 10, // 每页数量
        list: [], // 评论列表
        checkedList: [] // 选择要删除的评论
      }
    },
    mounted(){
      console.log('我的评论')
      //监听scroll事件
      window.addEventListener('scroll', this.handleScroll, true)
      this.checkedFilter = this.filter[0] // 默认全部类型评论
      this.getMyComment()
    },
    destroyed() {
      //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
      window.removeEventListener('scroll', this.handleScroll)
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    methods: {
      // 评论类型筛选
      checkType(type) {
        if(this.checkedFilter != type){
          this.edit = false // 关闭管理样式
          this.checkedFilter = type
          this.pageIndex = 1
          this.list = []
          this.getMyComment()
        }
      },
      // 获取我的评论
      getMyComment() {
        if(this.loading) return
        this.loading = true
        let me = this
        this.$post({
          url: 'userCriticism/slicedQueryMyUserCriticism',
          params: {
            token: this.$global.token,
            bizType: this.checkedFilter.code,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sortName: '',
            sortOrder: ''
          }
        }).then( rsp => {
          console.log(rsp.data)
          me.loading = false
          me.list = me.list.concat(rsp.data.data)
          me.pageCount = Math.ceil(rsp.data.count / me.pageSize)
        }).catch( error => {
          console.log(error)
          me.loading = false
          Toast(error.message)
        })
      },
      // 显示管理
      showEdit() {
        if(this.list.length < 1) {
          Toast('无可管理数据')
          return
        }
        this.checkedList = []
        this.edit = true
      },
      // 选择要删除的评论
      check(item) {
        let i, hasItem = false, temporary = [];
        for(i in this.checkedList){
          if(this.checkedList[i] == item){
            hasItem = true
            continue
          }
          temporary.push(this.checkedList[i])
        }
        if(hasItem){
          this.checkedList = temporary
        }else{
          this.checkedList.push(item)
        }
      },
      // 是否选中
      isChecked(item){
        let i;
        for(i in this.checkedList){
          if(this.checkedList[i] == item){
            return true
          }
        }
        return false
      },
      // 全选
      checkall() {
        this.checkedList = []
        let i;
        for(i in this.list){
          this.checkedList.push(this.list[i])
        }
      },
      // 反选
      clearall() {
        let temporary = [];
        let i;
        for(i in this.list){
          if(!this.isChecked(this.list[i])){
            temporary.push(this.list[i])
          }
        }
        this.checkedList = temporary
      },
      // 删除
      del() {
        if(this.checkedList.length < 1){
          Toast('请选择需要删除的评论')
        }else{
          if(this.loading) return
          this.loading = true
          let i, criticismIdStr = [], me = this;
          for(i in this.checkedList){
            criticismIdStr.push(this.checkedList[i].criticismId)
            criticismIdStr.push('；')
          }
          criticismIdStr = criticismIdStr.join('')
          criticismIdStr = criticismIdStr.substr(0, criticismIdStr.length -1)
          console.log(criticismIdStr)
          this.$post({
            url: 'userCriticism/deleteUserCriticisms',
            params: {
              token: this.$global.token,
              criticismIdStr: criticismIdStr
            }
          }).then( rsp => {
            me.loading = false
            if(rsp.status == 1){
              me.edit = false
              me.checkedList = []
              me.list = []
              me.pageIndex = 1
              me.getMyComment()
              Toast('删除成功！')
            }

          }).catch( error => {
            console.log(error)
            me.loading = false
            Toast(error.message)
          })
        }
      },
      // 监听滚动条
      handleScroll() {
        let scrollTop = document.getElementsByTagName('body')[0].scrollTop
        let windowHeight = document.getElementById('app').clientHeight
        let bodyHeight = document.getElementById('container') ? document.getElementById('container').clientHeight - 10 : 0;
        if(scrollTop + windowHeight >= bodyHeight && !this.loading && this.pageIndex < this.pageCount){
          this.pageIndex++
          this.getMyComment()
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 50px 0 60px 0;
    box-sizing: border-box;
    background:  #F7F7F7;
    .filter{
      background: #fff;
      position: fixed;
      width: 100%;
      top: 0px;
      height: 50px;
      line-height: 50px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      z-index: 1;
      font-size: 0;
      box-shadow: 0 0 5px #ccc;
      ul {
        height: 60px;
        padding: 0 20px;
        box-sizing: border-box;
        overflow: hidden;
        overflow-x: auto;
      }
      a {
        font-size: 15px;
        color: #444;
        margin-left: 20px;
        display: inline-block;
        position: relative;
        transition: all 0.25s linear;
        i {
          position: absolute;
          width: 0;
          left: 50%;
          bottom: 8px;
          height: 4px;
          background: linear-gradient(to right, #7ddb4f, #21dd53);
          transition: all 0.25s linear;
        }
      }
      a.active{
        color: #000;
        font-size: 18px;
        i {
          width: 100%;
          left: 0;
        }
      }
      a:first-of-type {
        margin-left: 0;
      }
    }
    .list {
      font-size: 0;
      .nodata{
        height: calc(100vh - 110px);
        display: flex;
        align-items: center;/*垂直居中*/
        justify-content: center;/*水平居中*/
        img{
          width: 50%;
        }
      }
      .item{
        background: #fff;
        margin-top: 10px;
        width: 100%;
        display: inline-block;
        overflow: hidden;
        padding: 15px;
        box-sizing: border-box;
        position: relative;
        .box{
          width: 100%;
          transition: all 0.25s linear;
          position: relative;
          left: 0;
          .user{
            display: flex;
            justify-content: space-between;
            .info{
              display: inline-block;
              height: 40px;
              line-height: 45px;
              font-size: 16px;
              img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                vertical-align: middle;
                margin: -8px 10px 0 0;
              }
            }
            a{
              font-size: 14px;
              color: #555;
              line-height: 40px;
              i {
                width: 16px;
                height: 16px;
                display: inline-block;
                vertical-align: middle;
                background: url(../assets/img/icon-like.png) no-repeat center center/cover;
                margin: -3px 5px 0 0;
              }
            }
            a.like{
              color: #ff3e6e;
              i {
                background: url(../assets/img/icon-likeOn.png) no-repeat center center/cover;
              }
            }
          }
          .time{
            font-size: 12px;
            line-height: 30px;
            color: #888;
          }
          .text{
            font-size: 16px;
            color: #333;
          }
          .for{
            margin-top: 10px;
            padding: 10px;
            box-sizing: border-box;
            background: url(../assets/img/icon-more.png) no-repeat 97% center/auto 15px #f7f7f7;
            img{
              width: 70px;
              height: 70px;
              margin-right: 10px;
              vertical-align: top;
            }
            div{
              width: calc(100% - 90px);
              display: inline-block;
              vertical-align: top;
              h1{
                font-size: 14px;
              }
              p{
                margin-top: 5px;
                height: 40px;
                line-height: 20px;
                font-size: 12px;
                color: #888;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
    .edit{
      .item{
        .checkbox {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #666;
          position: absolute;
          left: 20px;
          top: 20px;
          cursor: pointer;
        }
        .checked{
          background: url(../assets/img/icon-checked.png) no-repeat center center/70% auto #ff3e6d;
          border-color: #ff3e6d;
        }
        .box{
          left: 40px;
        }
      }
    }
    .manage{
      width: 100%;
      height: 50px;
      background: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      box-shadow: 0 0 5px #ccc;
      a.toedit{
        display: block;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(to right, #7ddb4f, #21dd53);
        color: #fff;
        text-align: center;
        font-size: 18px;
        transition: all 0.25s linear;
      }
      a.editnow{
        height: 0;
      }
      .btns{
        display: flex;
        justify-content: space-between;
        a{
          font-size: 16px;
          line-height: 50px;
          margin: 0 20px;
          color: #333;
        }
      }
    }
  }
</style>
